import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { Jumbotron, Layout, SEO } from "../ui";

const NotFoundPage = ({
  data,
  location,
}: PageProps<{ site: { siteMetadata: { title: string } } }>) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <Jumbotron>
        <h1>404: Not Found</h1>
      </Jumbotron>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
